import axios from 'axios';

import { withEventEmitter } from 'helpers/events';
import currency from 'services/currency';

const token = "YmxvZ3dlcGxhbm5lczpJZWhYcEF6RWhPVlg2VXZLcHpSSGhoZlV3bUJuTWhxQQ==";

// const BASE_URL = 'https://developer.weplann.com/api/';
const BASE_URL = 'JSON/';
let BASE_URL_ = '';

var config = {
    headers: {
        'Authorization': "Basic " + token,
    }
};

const api = {

    getUrl (action, data = {}) {

        let endPoint = ''
        const { language, query, id } = data;

        switch (action) {

            case 'getLanguages':

                endPoint = `languages/index.json`;

                break;

            case 'getTranslations':

                endPoint = `languages/translations/${language}.json`;

                break;

            case 'getCities':

                endPoint = `cities/${language}.json`;
                break;

                //BASE_URL_ = 'http://localhostapache/cors-proxy/?csurl=https://developer.weplann.com/api/';
                // BASE_URL_ = 'https://weplann.react.heldev.net/api/?csurl=https://developer.weplann.com/api/';
                
                // endPoint = `${language}/pages?pagetypecode=city`
                
                // return BASE_URL_+endPoint;

                

            case "getProducts":
                
                //BASE_URL_ = 'http://localhostapache/cors-proxy/?csurl=https://developer.weplann.com/api/';
                BASE_URL_ = 'https://api-weplann.heldev.net/?csurl=https://developer.weplann.com/api/';
                
                endPoint = `${language}/pages?query=${query}&pagetypecode=product`
                
                return BASE_URL_+endPoint;

            case "getCityProducts":
                
                //BASE_URL_ = 'http://localhostapache/cors-proxy/?csurl=https://developer.weplann.com/api/';
                BASE_URL_ = 'https://api-weplann.heldev.net/?csurl=https://developer.weplann.com/api/';
                
                endPoint =`${language}/pagesearch/${id}?q=&cc=${currency.current}`
                
                return BASE_URL_+endPoint;

            case "getProduct":
            
                //BASE_URL_ = 'http://localhostapache/cors-proxy/?csurl=https://developer.weplann.com/api/';
                BASE_URL_ = 'https://api-weplann.heldev.net/?csurl=https://developer.weplann.com/api/product/';
                
                endPoint = `${id}`
                
                return BASE_URL_+endPoint;

            default:

                break;

        }

        return `${BASE_URL}${endPoint}`
    },

    fetch (url, method = 'GET', preloadedObject = null) {

        return new Promise(
            (resolve, reject) => {
                
                if (preloadedObject) {
                    resolve(preloadedObject);
                    return;
                }

                setTimeout(
                    () => {
                        axios[method.toLowerCase()](url,config).then(
                            success => {
                                localStorage.setItem(url, JSON.stringify(success.data));
                                resolve(success.data)
                            },
                            error => {
                                reject(error)
                                throw(error)
                            }
                        )
                    }, 0
                )
                
            }
        )

    }
}

export default withEventEmitter(api);