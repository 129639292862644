import React from 'react';
import ReactDOM from 'react-dom';
import App from 'App';

import 'styles/index.css';
import services from 'services/index';

services.initialize();

setTimeout(
  () => {
    ReactDOM.render(
      <App />,
      document.getElementById('root')
    );
  }
, 0)
