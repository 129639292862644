import { withEventEmitter } from 'helpers/events';
import { getUnique } from 'helpers/arrays';
import api from 'services/api';
import i18n from 'services/i18n';

const products = {

    _list : {},

    initialize : () => {

        i18n.on('languages-loaded', 

            ()=> {

                i18n.languages.map(
                    language => {

                        if (!products._list[language.code]) {
                            products._list[language.code] = []
                        }

                        return null
                    }
                )

                return null
            }
            
        )
    },

    get list () {
        return products._list[i18n.language]?products._list[i18n.language]:[];
    },

    set list (_products) {
        return products._list[i18n.language] = _products;
    },

    addTolist (_products) {
        products._list[i18n.language] = getUnique([...new Set ([...products._list[i18n.language], ..._products])], 'id');
    },

    processPromises: (promises, url) => {
        return promises.map(
            (preloadedObject) => {
                return new Promise(
                    (resolve, reject) => {
                        return api.fetch(url, 'GET', preloadedObject)
                        .then(
                            (data) => {
                                try{
                                    const _products = products.bridge(data.productPages || data)
                                    products.emit('products-loaded', _products)
                                    resolve(_products);
                                } catch(e) {
                                    products.emit('product-loaded', data)
                                    resolve(data)
                                }
                            }
                        )
                        .catch(
                            (error) => {
                                reject(error)
                            }
                        )
        
                    }
                )
            }
            
        )
    },

    search: (query) => {

        const url = api.getUrl('getProducts', { language: i18n.language, query: query })
        
        const promises = [JSON.parse(localStorage.getItem(url)), null]
        
        return Promise.race(products.processPromises(promises, url))
    },

    searchByCity: (id) => {

        const url = api.getUrl('getCityProducts', { language: i18n.language, id: id })
        
        const promises = [JSON.parse(localStorage.getItem(url)), null]

        return Promise.race(products.processPromises(promises, url))
    },

    getProduct: (id) => {

        const url = api.getUrl('getProduct', { language: i18n.language, id: id })

        const promises = [JSON.parse(localStorage.getItem(url)), null]

        return Promise.race(products.processPromises(promises, url))
    },

    showProductData: (id) => {

        products.getProduct(id);

        products.on('product-loaded',
        
          productsList => {

            console.log('productsList', productsList)

          }
          
        )
    },

    bridge: (_products) => {
        
        return _products.values.filter(
              
            product => {

                product.images = {
                    thumbnail: product.imageUrl
                }

                return product
            }
        )
    }
    

}

export default withEventEmitter(products);