import React, { useEffect, useState, useRef } from 'react';

import services from 'services/index';

export const withEventedForceUpdate = (WrappedComponent, events) => {
    return (props) => {
        
        const [key, setKey] = useState();
        const mounted = useRef(true);

        const forceUpdate = () => {

            setTimeout(
                ()=> {
                    if (mounted.current) {
                        setKey(+ new Date());
                    }
                }
            , 0)
            
        }

        useEffect(() => {

            events.map(
                ({service, event}) => {
                    return services[service].on(
                        event, 
                        forceUpdate
                    )
                }
            )
            
            return () => {
                mounted.current = false;
            }

        }, []);

        return (
            <React.Fragment key={key}>
                <WrappedComponent {...props} />
            </React.Fragment>
        );
    }
}