import { withEventEmitter } from 'helpers/events';
import { getDescendantProp } from 'helpers/objects'
import device from 'services/device';

const styles = {
    global: {
        padding: {
            general: '20px'
        },
        colors: {
            primary: '#009aca',
            secondary: '#ef843d',
            white: '#fff',
            gray: '#3b3b3b',
            lightGray: '#999999'
        },
        images: {
            logo: 'logo'
        },
        borderRadius: 0
    },
    big: {
        padding: {
            general: '20px'
        },
        size:{
            pageWidth: '1180px',
            navigator:{
                height: '80px'
            }
        }
    },
    medium: {
        size:{
            pageWidth: '100%',
            navigator:{
                height: '60px'
            }
        }
    },
    small: {
        size:{
            pageWidth: '100%',
            navigator:{
                height: '50px',
                fontSize: '0.7rem'
            }
        },
        padding: {
            general: '17px'
        },
        images: {
            logo: 'icon'
        }
    },
    mobile: {
        position: {
            navigator:{
                position: 'fixed',
                bottom: 0,
                right: 0,
                left: 0
            }
        },
        size:{
            pageWidth: '100%',
            navigator:{
                height: '50px'
            }
        }
    },
    getStyle(style){
        return (
            getDescendantProp(styles, (device.isMobileDevice?'mobile.':null) + style) ||
            getDescendantProp(styles, device.window.mode+'.' + style) || 
            getDescendantProp(styles, 'global.' + style)
        )
    }
}

export default withEventEmitter(styles);