import { withEventEmitter } from 'helpers/events';
import history from 'services/history';

const navigation = {

    navigateTo: (route) => {
        history.push(route);
    },

    navigateTo404: () => {
        history.replace('/404');
    }
}

export default withEventEmitter(navigation);