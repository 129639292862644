import { withEventEmitter } from 'helpers/events';

const currency = {

    current_ : null,

    available: [
        'USD',
        'EUR',
        'GBP',
        'MXN',
        'BRL'
    ],

    initialize : () => {

        currency.current = 'EUR'

    },
    get current() {
        return currency.current_
    },
    set current(currency_) {
        currency.current_ = currency_;
        setTimeout(
            () => currency.emit('currency-changed', currency_)
        , 0)
        
    },
    setCurrency (currency_) {
        currency.current = currency_
    }
}

export default withEventEmitter(currency);