import React, { Suspense, lazy } from 'react';
import { Router } from 'react-router-dom';
import history from 'services/history';

import { withEventedForceUpdate } from 'helpers/components';

const Header = lazy(() => import('components/header/index'));
const RouterSwitch = lazy(() => import('router/index'));

export const App = () => {
  return (
    <Router history={history}>
        <Suspense fallback={null}>
          <Header />
          <RouterSwitch />
        </Suspense>
    </Router>
  );
}

export default withEventedForceUpdate(
  App, 
    [
      {service: 'i18n', event:'language-changed'}
    ]
);