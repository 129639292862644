import { animateScroll as scroll } from 'react-scroll'

import styles from 'styles/index';
import { withEventEmitter } from 'helpers/events';
import { delay } from 'helpers/time';
import device from 'services/device';
import cities from 'services/cities';
import products from 'services/products';
import navigation from 'services/navigation';

const searchBox = {

    query: null,

    getOptions: () => {
        
        const list = [
            ...cities.list, 
            ...products.list
        ]

        return list.map(option => {
            const firstLetter = option.name[0].toUpperCase();
            return {
              firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
              ...option,
            };
        })
    },

    onChange: (value) => {
        
        if (value) {
            delay(function(){
                
                if(device.isMobileDevice) {

                    document.getElementById('home-searchbox').blur();

                    scroll.scrollTo(
                        0,
                        {
                            duration: 800,
                            delay: 0,
                            smooth: 'easeInOutQuart'
                        }
                    );
                }

                searchBox.search(value)
                
            }, 1000 );
        }
        
    },

    onOptionClick: (event) => {

        const { name, pagetypecode, url, label } = event.target.dataset;

        searchBox.emit('value-selected', name)

        switch (pagetypecode) {

            case "city":
                navigation.navigateTo(label);
                return

            case "product":
                document.location.href = url;
                return

            default:
                return
        }
    },

    onFocus: (element) => {

        if(!device.isMobileDevice) {
            scroll.scrollTo(
                element.getBoundingClientRect().top-parseInt(styles.getStyle('padding.general'))*2,
                {
                    duration: 800,
                    delay: 0,
                    smooth: 'easeInOutQuart'
                }
            );
        }
    },

    search: (query) => {

        searchBox.query = query

        searchBox.emit('searching', true)

        products.search(query).then(
            products_ => {
                
                if (products_ && products_.length) {
                    
                    products.addTolist(products_)
                    searchBox.getOptions()
                    searchBox.emit('options-changed')
                }

                if (searchBox.query === query) {
                    searchBox.emit('searching', false)
                }
                    
            }
        )
    },

    closePopup: () => {
        setTimeout(
            ()=>searchBox.emit('close')
        , 100)
    },

    onOpen: () => {
        setTimeout(
            ()=>searchBox.emit('open')
        , 100)
    }

}

export default withEventEmitter(searchBox);