import { withEventEmitter } from 'helpers/events';

import api from 'services/api';

const i18n = {

    _language : null,

    _translations: {},

    initialize : () => {

        i18n.language = 'es'

        i18n.on('languages-loaded', 
            languages => {
                i18n.languages = languages;
                i18n.languages.map(
                    language => i18n.fetchTranslations(language)
                )
            }
        )

        i18n.on('translations-loaded', 
            (language, translations) => i18n.translations[language] = translations
        )

        i18n.fetchLanguages()

    },

    get language() {
        return i18n._language;
    },

    set language(language) {

        i18n._language = language;
        i18n.emit('language-changed', language);

    },

    get languageName() {

        if (i18n.languages) {
            return i18n.languages.find(language => { return language.code === i18n.language}).name
        }
        
        return null

    },

    get translations () {
        return i18n._translations;
    },

    get languages () {
        return i18n._languages;
    },

    set languages (languages) {
        return i18n._languages = languages;
    },

    translate: (label) => {
        if (i18n.translations[i18n.language]) {
            if (i18n.translations[i18n.language][label]) {
                return i18n.translations[i18n.language][label];
            } else {
                console.warn(`Translated string for %c[${label}] %cin ${i18n.languageName} not found`, 'font-weight: bold; color: red');
                return label;
            }
        } else {
            return null
        }
    },

    fetchLanguages: () => {

        let _promises = []

        const url = api.getUrl('getLanguages')

        const promises = [require(`data/languages/index.json`), JSON.parse(localStorage.getItem(url)), null]
        
        _promises = promises.map(
            (preloadedObject) => {
                return new Promise(
                    (resolve, reject) => {
                        api.fetch(url, 'GET', preloadedObject)
                        .then(
                            (data) => {
                                i18n.emit('languages-loaded', data)
                            }
                        )
                        .catch(
                            (error) => {
                                reject(error)
                            }
                        )
        
                    }
                )
            }
            
        )

        return Promise.race(_promises)
        
    },

    fetchTranslations: (language) => {

        let _promises = []

        let url = api.getUrl('getTranslations', { language: language.code })

        let promises = [require(`data/languages/translations/${language.code}.json`), JSON.parse(localStorage.getItem(url)), null]

        _promises = promises.map(
            (preloadedObject) => {
                return new Promise(
                    (resolve, reject) => {
                        api.fetch(url, 'GET', preloadedObject)
                        .then(
                            (data) => {

                                if (language.code === i18n.language) {
                                    i18n.emit('current-language-translations-loaded')
                                }

                                i18n.emit('translations-loaded', language.code, data)
                            }
                        )
                        .catch(
                            (error) => {
                                reject(error)
                            }
                        )

                    }
                )
            }
            
        )

        return Promise.race(_promises)
        
    },

    // Action functions
    setLanguage (language) {
        i18n.language = language;
    }
}

export default withEventEmitter(i18n);