import device from 'services/device';
import navigation from 'services/navigation';
import currency from 'services/currency';
import i18n from 'services/i18n';
import cities from 'services/cities';
import products from 'services/products';
import searchBox from 'services/searchBox';

const services_ = {
    device,
    navigation,
    currency,
    i18n,
    cities,
    products,
    searchBox
}

const services = {
    
    ...services_,

    initialize: () => {

        Object.keys(services_).map(key => {
            
            const service = services_[key]

            if (typeof service.initialize === "function") {
                service.initialize()
            }

            return null
        });
        
    },

    execute: (action) => {
        const action_ = action.split("/")
        services[action_[1]][action_[2]](action_[3])
    }
    
}

export default services;