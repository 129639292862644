import { withEventEmitter } from 'helpers/events';

import api from 'services/api';
import i18n from 'services/i18n';

const cities = {

    _list : {},

    _initialize : () => {

        cities.on('cities-loaded', 
            data => {
                cities.list = data
            }
        )

        cities.fetch();
    },

    get list () {
        return cities._list[i18n.language];
    },

    set list (_cities) {
        return cities._list[i18n.language] = _cities;
    },

    getCity (_city) {
        return cities._list[i18n.language].filter(__city => __city.label === _city);
    },

    fetch: () => {

        let _promises = []

        const url = api.getUrl('getCities', { language: i18n.language })
        
        const promises = [require(`data/cities/`+i18n.language+`.json`), JSON.parse(localStorage.getItem(url)), null]
        
        _promises =  promises.map(
            (preloadedObject) => {
                return new Promise(
                    (resolve, reject) => {
        
                        return api.fetch(url, 'GET', preloadedObject)
                        .then(
                            (data) => {
                                cities.emit('cities-loaded', data)
                            }
                        )
                        .catch(
                            (error) => {
                                reject(error)
                            }
                        )
        
                    }
                )
            }
            
        )

        return Promise.race(_promises)
        
    }
}

i18n.on('language-changed', cities._initialize)

export default withEventEmitter(cities);