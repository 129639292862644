import { addEvent, withEventEmitter } from 'helpers/events';

const screenModes = {
    small: {
        min: 0,
        max: 767
    },
    medium: {
        min: 768,
        max: 940
    },
    big: {
        min: 768,
        max: 10000000
    }
}

const device = {
    
    window: {
        width: 0,
        mode: ''
    },

    initialize : () => {

        device.screenWidth = window.innerWidth;
        device.screenMode = window.innerWidth;

        addEvent(
            window, 
            "resize", 
            (e)=>{
                device.screenWidth = e.target.outerWidth;
                device.screenMode = e.target.outerWidth;
            }
        );

    },
    get screenWidth() {
        return device.window.width
    },
    set screenWidth(width) {
        device.window.width = width
    },
    get screenMode() {
        return device.window.mode
    },
    set screenMode(width) {

        const screenModes_ = Object.keys(screenModes)

        screenModes_.some(
            (mode) => {

                let oldMode = device.window.mode

                if (screenModes[mode].min <= width && screenModes[mode].max >= width) {

                    device.window.mode = mode

                    if (oldMode !== mode) {
                        device.emit('window-mode-changed')
                    }

                    return true
                }

                return null
            }
        )
        
    },
    get isMobileDevice() {
        return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);
    },
    get canUseWebP() {
        return document.createElement('canvas').toDataURL('image/webp').indexOf('data:image/webp') === 0;
    },
    get isChrome() {
        return navigator.userAgent.indexOf("Chrome") !== -1
    }
}

export default withEventEmitter(device);